var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("context-title", {
        attrs: { title: "Menu", "passed-actions": _vm.titleBarActions },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex align-center fill-height" },
                  [
                    _c(
                      "v-chip",
                      { attrs: { color: "#4A90E2", label: "", outlined: "" } },
                      [_vm._v(" " + _vm._s(_vm.languageDisplay) + " ")]
                    ),
                    _c("h6", { staticStyle: { "padding-left": "7px" } }, [
                      _vm._v("Menu"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-container",
        { staticClass: "fill-height fluid" },
        [
          _c(
            "v-dialog",
            {
              staticStyle: { "z-index": "9999" },
              attrs: { width: "400" },
              model: {
                value: _vm.autoTranslateVisible,
                callback: function ($$v) {
                  _vm.autoTranslateVisible = $$v
                },
                expression: "autoTranslateVisible",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h5 grey lighten-2" },
                    [_vm._v(" Attention ")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " All menu translations will be wiped and reverted back to machine translated words/phrases. "
                    ),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { block: "", large: "" },
                              on: {
                                click: function ($event) {
                                  _vm.autoTranslateVisible = false
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-2",
                              attrs: { block: "", color: "#93BD20", large: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.autoTranslate()
                                },
                              },
                            },
                            [_vm._v(" Continue ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-text-field", {
                staticStyle: { "max-width": "400px" },
                attrs: {
                  id: "search",
                  label: "Search menu items",
                  "append-icon": "search",
                  dense: "",
                  "background-color": "#fff",
                  outlined: "",
                  clearable: "",
                  "hide-details": "",
                },
                on: {
                  "click:clear": function ($event) {
                    _vm.filteredPhrases = _vm.phrases.value
                  },
                  input: _vm.debounced_searchChange,
                },
                model: {
                  value: _vm.searchFilter,
                  callback: function ($$v) {
                    _vm.searchFilter = $$v
                  },
                  expression: "searchFilter",
                },
              }),
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { sm: "2" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.perPageItems,
                      label: "Phrases per page",
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                    },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0 mb-0", attrs: { "no-gutters": "" } },
            [
              _c("v-checkbox", {
                attrs: { label: `Only show untranslated phrases` },
                on: { change: _vm.debounced_searchChange },
                model: {
                  value: _vm.onlyUntranslated,
                  callback: function ($$v) {
                    _vm.onlyUntranslated = $$v
                  },
                  expression: "onlyUntranslated",
                },
              }),
            ],
            1
          ),
          _vm.phrases.status === _vm.OperationStatus.InProgress
            ? _vm._l(8, function (i) {
                return _c("v-skeleton-loader", {
                  key: i,
                  staticClass: "mt-2",
                  attrs: {
                    type: "list-item-three-line",
                    elevation: "2",
                    width: "100%",
                  },
                })
              })
            : _vm.phrases.status === _vm.OperationStatus.Failed
            ? _c("div", [
                _c("h4", { staticClass: "languages-error__text" }, [
                  _vm._v(_vm._s(_vm.phrases.error)),
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "languages-error__button",
                        attrs: { color: "primary", outlined: "" },
                        on: {
                          click: function ($event) {
                            return _vm.fetchPhrases(
                              _vm.waypointId,
                              _vm.language
                            )
                          },
                        },
                      },
                      [_vm._v("Retry")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.phrases.status === _vm.OperationStatus.Succeeded &&
          _vm.phrases.value.length > 0 &&
          _vm.filteredPhrases.length === 0
            ? [
                _c("h3", { staticStyle: { "margin-top": "10px" } }, [
                  _vm._v(
                    " No phrases matches your query, try to refine the search phrase. "
                  ),
                ]),
              ]
            : [
                _c(
                  "v-row",
                  {
                    staticClass: "mt-0",
                    attrs: { "no-gutters": "", justify: "center" },
                  },
                  [
                    _c("v-pagination", {
                      attrs: { length: _vm.numPages, "total-visible": "10" },
                      model: {
                        value: _vm.page,
                        callback: function ($$v) {
                          _vm.page = $$v
                        },
                        expression: "page",
                      },
                    }),
                  ],
                  1
                ),
                _vm._l(
                  _vm.filteredPhrases.slice(
                    (_vm.page - 1) * _vm.perPage,
                    (_vm.page - 1) * _vm.perPage + _vm.perPage
                  ),
                  function (phrase) {
                    return _c(
                      "v-row",
                      {
                        key: phrase.value,
                        staticClass: "mt-2",
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "12" } },
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "black--text" },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { sm: "auto" } },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  label: "",
                                                  outlined: "",
                                                  small: "",
                                                },
                                              },
                                              [_vm._v("EN")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-col", { attrs: { md: "7" } }, [
                                          _vm._v(
                                            " " + _vm._s(phrase.phrase) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-0" },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { sm: "auto" } },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  color: "#4A90E2",
                                                  label: "",
                                                  outlined: "",
                                                  small: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      phrase.languageCode.toUpperCase()
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "7" } },
                                          [
                                            _c("v-textarea", {
                                              attrs: {
                                                value: phrase.translatedPhrase,
                                                "hide-details": "",
                                                dense: "",
                                                outlined: "",
                                                "auto-grow": "",
                                                "background-color": "#fff",
                                                rows: "1",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.debounced_phraseChange(
                                                    $event,
                                                    phrase.phrase
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("br"),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "", justify: "center" } },
                  [
                    _c("v-pagination", {
                      attrs: { length: _vm.numPages, "total-visible": "10" },
                      model: {
                        value: _vm.page,
                        callback: function ($$v) {
                          _vm.page = $$v
                        },
                        expression: "page",
                      },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }