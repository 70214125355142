<template>
  <div>
    <context-title title="Menu" :passed-actions="titleBarActions">
      <template v-slot:title>
        <div class="d-flex align-center fill-height">
          <v-chip color="#4A90E2" label outlined>
            {{ languageDisplay }}
          </v-chip>
          <h6 style="padding-left: 7px">Menu</h6>
        </div>
      </template>
    </context-title>
    <v-container class="fill-height fluid">
      <v-dialog
        width="400"
        v-model="autoTranslateVisible"
        style="z-index: 9999"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Attention
          </v-card-title>

          <v-card-text>
            All menu translations will be wiped and reverted back to machine
            translated words/phrases.
          </v-card-text>

          <v-card-actions class="ma-0 pa-0">
            <v-col class="ma-0 pa-0">
              <v-btn block large @click="autoTranslateVisible = false">
                Cancel
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                class="pa-2"
                color="#93BD20"
                large
                @click="autoTranslate()"
              >
                Continue
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row no-gutters>
        <v-text-field
          id="search"
          v-model="searchFilter"
          label="Search menu items"
          append-icon="search"
          style="max-width: 400px"
          dense
          background-color="#fff"
          outlined
          clearable
          hide-details
          @click:clear="filteredPhrases = phrases.value"
          @input="debounced_searchChange"
        />
        <v-spacer />
        <v-col sm="2"
          ><v-select
            v-model="perPage"
            :items="perPageItems"
            label="Phrases per page"
            outlined
            hide-details
            dense
          ></v-select
        ></v-col>
      </v-row>
      <v-row no-gutters class="mt-0 mb-0">
        <v-checkbox
          v-model="onlyUntranslated"
          :label="`Only show untranslated phrases`"
          @change="debounced_searchChange"
        ></v-checkbox>
      </v-row>
      <template v-if="phrases.status === OperationStatus.InProgress">
        <v-skeleton-loader
          v-for="i in 8"
          :key="i"
          type="list-item-three-line"
          elevation="2"
          width="100%"
          class="mt-2"
        />
      </template>
      <div v-else-if="phrases.status === OperationStatus.Failed">
        <h4 class="languages-error__text">{{ phrases.error }}</h4>
        <div class="d-flex justify-content-center">
          <v-btn
            class="languages-error__button"
            color="primary"
            outlined
            @click="fetchPhrases(waypointId, language)"
            >Retry</v-btn
          >
        </div>
      </div>
      <template
        v-if="
          phrases.status === OperationStatus.Succeeded &&
          phrases.value.length > 0 &&
          filteredPhrases.length === 0
        "
      >
        <h3 style="margin-top: 10px">
          No phrases matches your query, try to refine the search phrase.
        </h3>
      </template>
      <template v-else>
        <v-row no-gutters justify="center" class="mt-0"
          ><v-pagination
            v-model="page"
            :length="numPages"
            total-visible="10"
          ></v-pagination
        ></v-row>
        <v-row
          class="mt-2"
          no-gutters
          v-for="phrase of filteredPhrases.slice(
            (page - 1) * perPage,
            (page - 1) * perPage + perPage
          )"
          :key="phrase.value"
        >
          <v-col md="12">
            <v-card>
              <v-card-text class="black--text">
                <v-row>
                  <v-col sm="auto">
                    <v-chip label outlined small>EN</v-chip>
                  </v-col>
                  <v-col md="7">
                    {{ phrase.phrase }}
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col sm="auto">
                    <v-chip color="#4A90E2" label outlined small>
                      {{ phrase.languageCode.toUpperCase() }}
                    </v-chip>
                  </v-col>
                  <v-col md="7">
                    <v-textarea
                      :value="phrase.translatedPhrase"
                      hide-details
                      dense
                      outlined
                      auto-grow
                      background-color="#fff"
                      rows="1"
                      @input="debounced_phraseChange($event, phrase.phrase)"
                    />
                  </v-col>
                </v-row>
                <br />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters justify="center"
          ><v-pagination
            v-model="page"
            :length="numPages"
            total-visible="10"
          ></v-pagination
        ></v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import ContextTitle from "components/context_title";
import LineItem from "components/line_item";
import { authHttp, passthrough } from "@/http";
import { OperationStatus } from "helpers/common";
import moment from "moment";
import _ from "lodash";

export default {
    name: "edit_translations",
    components: {
        ContextTitle,
        LineItem,
    },
    data() {
        return {
            searchFilter: "",
            phrases: {
                status: OperationStatus.Unknown,
                error: null,
                value: [],
            },
            phrasesSubmit: {
                status: OperationStatus.Unknown,
                error: null,
            },
            dirtyPhrases: {},
            filteredPhrases: [],
            autoTranslateVisible: false,
            perPage: 10,
            perPageItems: [10, 25, 50, 100],
            page: 1,
            onlyUntranslated: false,
        };
    },
    mounted() {
        this.fetchPhrases(this.waypointId, this.language);
    },
    created() {
        this.debounced_searchChange = _.debounce(this.onSearchChange);
        this.debounced_phraseChange = _.debounce(this.onPhraseChange);
    },
    methods: {
        fetchPhrases(waypointId, language) {
            this.phrases.error = null;
            this.phrases.value = [];
            this.searchFilter = ""

            if (!waypointId || !language) {
                this.phrases.status = OperationStatus.Unknown;
                return;
            }
            this.phrases.status = OperationStatus.InProgress;
            authHttp
                .get(
                    `/api/languages/waypoint/${this.waypointId}/phrases/${this.language}`
                )
                .then((res) => {
                    this.phrases.value = res.data.phrases.map((x) => ({
                        ...x,
                        humanReadableLastUpdatedBy: moment(x.lastUpdatedOn).format(
                            "DD/MM/YYYY HH:mm"
                        ),
                    }));
                    this.filteredPhrases = this.phrases.value;
                    this.phrases.status = OperationStatus.Succeeded;
                })
                .catch((e) => {
                    this.phrases.status = OperationStatus.Failed;
                    const errorMessage =
            "Unexpected error occurred during fetching of translated phrases, please try again or contact tech support";
                    this.toast(errorMessage, "error");
                    this.phrases.error = errorMessage;
                    console.error(errorMessage, e);
                });
        },
        saveTranslatedPhrases() {
            if (this.phrasesSubmit.status === OperationStatus.InProgress) {
                return;
            }
            this.phrasesSubmit.status = OperationStatus.InProgress;
            const phrasesToSubmit = Object.keys(this.dirtyPhrases).map((x) => ({
                phrase: x,
                translatedPhrase: this.dirtyPhrases[x],
                languageCode: this.language,
            }));
            const errorMessage =
        "Unexpected error occurred during submitting translated phrases, please try again or contact tech support";
            authHttp
                .post(
                    `/api/languages/waypoint/${this.waypointId}/phrases/${this.language}`,
                    {
                        phrases: phrasesToSubmit,
                    }
                )
                .then((res) => {
                    const { success, savedPhrases, exception } = res.data;
                    if (success) {
                        this.toast(
                            `Successfully submitted ${savedPhrases.length} phrase${
                                savedPhrases.length > 1 ? "s" : ""
                            }`,
                            "success"
                        );
                        this.phrasesSubmit.status = OperationStatus.Succeeded;

                        this.fetchPhrases(this.waypointId, this.language);
                    } else {
                        this.phrasesSubmit.status = OperationStatus.Failed;
                        this.toast(errorMessage, "error");
                        this.phrasesSubmit.error = errorMessage;
                        console.error(errorMessage, exception);
                    }
                })
                .catch((e) => {
                    this.phrasesSubmit.status = OperationStatus.Failed;
                    this.toast(errorMessage, "error");
                    this.phrasesSubmit.error = errorMessage;
                    console.error(errorMessage, e);
                });
        },
        autoTranslate() {
            this.phrases.status = OperationStatus.InProgress;
            this.autoTranslateVisible = false;

            this.toast("Translating Menu");

            let request = {
                url: "Cursus_TranslatorLocalize_LoadMenuTranslations",
                method: "GET",
                qs: {
                    waypointID: this.waypointId,
                    languageCode: this.language,
                },
            };
            const errorMessage =
        "Unexpected error occurred, please try again or contact tech support";
            passthrough(request)
                .then((res) => {
                    const { success, exception } = res.data;
                    if (success) {
                        this.toast(`Successfully translated menu`, "success");
                        this.phrases.status = OperationStatus.Succeeded;
                        this.fetchPhrases(this.waypointId, this.language);
                    } else {
                        this.phrases.status = OperationStatus.Failed;
                        this.toast(errorMessage, "error");
                        this.phrases.error = errorMessage;
                        console.error(errorMessage, exception);
                    }
                })
                .catch((e) => {
                    this.phrases.status = OperationStatus.Failed;
                    this.toast(errorMessage, "error");
                    this.phrases.error = errorMessage;
                    console.error(errorMessage, e);
                });
        },
        refreshMenuItems() {
            this.phrases.status = OperationStatus.InProgress;
            this.autoTranslateVisible = false;

            this.toast("Refreshing Menu Items");

            let request = {
                url: "CursusHub_RefreshMenuItemsForTranslation",
                method: "GET",
                qs: {
                    storeWaypointID: this.waypointId,
                },
            };
            const errorMessage =
        "Unexpected error occurred, please try again or contact tech support";
            passthrough(request)
                .then((res) => {
                    const { success, exception } = res.data;
                    if (success) {
                        this.toast(`Successfully refreshed menu items.`, "success");
                        this.phrases.status = OperationStatus.Succeeded;
                        this.fetchPhrases(this.waypointId, this.language);
                    } else {
                        this.phrases.status = OperationStatus.Failed;
                        this.toast(errorMessage, "error");
                        this.phrases.error = errorMessage;
                        console.error(errorMessage, exception);
                    }
                })
                .catch((e) => {
                    this.phrases.status = OperationStatus.Failed;
                    this.toast(errorMessage, "error");
                    this.phrases.error = errorMessage;
                    console.error(errorMessage, e);
                });
        },
        cancel() {
            this.$router.push({
                name: "languages",
            });
        },
        onSearchChange() {
            const trimmedSearchFilter =
        this.searchFilter == null ? "" : this.searchFilter.trim().toLowerCase();
            let res = [];
            if (trimmedSearchFilter.length === 0) {
                res = this.phrases.value;
            } else {
                res = this.phrases.value.filter(
                    (x) =>
                        x.phrase.toLowerCase().indexOf(trimmedSearchFilter) > -1 ||
            x.translatedPhrase.toLowerCase().indexOf(trimmedSearchFilter) > -1
                );
            }
            if (this.onlyUntranslated)
                res = res.filter((el) => el.phrase == el.translatedPhrase);
            this.filteredPhrases = res;
            this.page = 1;
        },
        onPhraseChange(input, phrase) {
            this.dirtyPhrases[phrase] = input;
        },
    },
    computed: {
        languageDisplay() {
            let languageNames = new Intl.DisplayNames(["en"], {
                type: "language",
            });
            return languageNames.of(this.language)
        },
        waypointId() {
            return this.$route.params.store;
        },
        language() {
            return this.$route.params.language;
        },
        numPages() {
            return Math.ceil(this.filteredPhrases.length / this.perPage);
        },
        titleBarActions() {
            return [
                {
                    type: "add-new",
                    display: "Refresh Menu Items",
                    run: () => {
                        this.refreshMenuItems();
                    },
                },
                {
                    type: "remove-red",
                    display: "Auto Translate",
                    run: () => {
                        this.autoTranslateVisible = true;
                    },
                },
                {
                    type: "cancel",
                    display: "Cancel",
                    run: () => {
                        this.cancel();
                    },
                },
                {
                    type: "proceed-green",
                    display: "Save",
                    run: () => {
                        this.saveTranslatedPhrases();
                    },
                },
            ];
        },
        OperationStatus: () => OperationStatus,
    },
    watch: {
        waypointId(val) {
            this.fetchPhrases(val, this.language);
        },
        language(val) {
            this.fetchPhrases(this.waypointId, val);
        },
    },
};
</script>

<style scoped>
.v-dialog {
  overflow: hidden !important;
}
</style>
